import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useParams } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import './List.css';
import BaseUrl from '../../Server/BaseUrl'
import Loader from '../../../assets/loader.gif';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const CencelBookingUserList = () => {
    const [cancellationReason, setCancellationReason] = useState('');
    const { userid } = useParams();
    const [state, setState] = useState({
        activeBt: '',
        apiData: [], // Initialize as an empty array
        loading: true,
        error: null,
        selectedProfileData: null,  // Add this to store profile data
        isProfileLoading: false,    // Add to handle profile loading state
        isProfilePopupOpen: false,
    });

    useEffect(() => {
        fetchData();
    }, [userid, state.activeBt]);

    const fetchData = async () => {
        const API_URL = `http://localhost:4000/active_booking_user/${userid}`;

        try {
            const response = await fetch(API_URL);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const filteredData = data?.message?.active_bookings?.filter(
                (booking) => booking.status === 'cancel by host' || booking.status === 'cancel by user'
            ) || [];

            setState(prevState => ({
                ...prevState,
                apiData: filteredData, // Ensure apiData is an array
                loading: false,
            }));
            console.log('data1111', data);
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                error: error.message,
                loading: false,
            }));
            alert(error.message);
        }
    };


    // Function to fetch profile data when "View Profile" is clicked
    const handleViewProfile = async (property_id, booking_id) => {
        const PROFILE_API_URL = `http://localhost:4000/getPropartBy_id`;

        setState((prevState) => ({
            ...prevState,
            isProfileLoading: true,
            selectedProfileData: null,  // Reset previous profile data
            isProfilePopupOpen: true,   // Open profile popup
        }));

        try {
            const response = await fetch(PROFILE_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    property_id: property_id,
                    booking_id: booking_id,
                }),
            });

            const profileData = await response.json();
            setState((prevState) => ({
                ...prevState,
                selectedProfileData: profileData.property_details,  // Store fetched profile data
                isProfileLoading: false,
            }));
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                error: error.message,
                isProfileLoading: false,
            }));
        }
    };
    const handleCloseProfilePopup = () => {
        setState((prevState) => ({
            ...prevState,
            isProfilePopupOpen: false,  // Close the profile popup
        }));
    };

    console.log("user view profile======>", state.selectedProfileData)


    if (state.loading) {
        return <div>Loading...</div>;
    }

    if (state.error) {
        return <div>Error: {state.error}</div>;
    }

    return (
        <div style={{ padding: '20px' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}> booking cancellation by User</h3>
            <div className='topMain' style={{ width: '100%' }}>
                {state.apiData.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No bookings available</p>
                ) : (
                    <div className="table-Page" style={{ overflowX: 'auto' }}>
                        <table className="table-full" style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>SERVICE</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>Entry Date & Exit Date</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Total</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Status</th>
                                    {/* <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {state.apiData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            {item.nights} Night + Rafting <br></br>
                                            Service by: {item.user_type === 'User' ? `${item.user_name}` : `${item.owner_name}`}.
                                            <button style={{ background: 'none', color: '#F15A29' }}
                                                onClick={() => handleViewProfile(item.property_id, item.booking_id)}
                                            >
                                                View Profile
                                            </button>
                                        </td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            {item.entry_date} to {item.exit_date}

                                        </td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>${item.total_amount}</td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <button style={{ padding: '5px 10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: 5 }}>
                                                {item.status}
                                            </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {/* Profile Dialog */}
            <Dialog open={state.isProfilePopupOpen} onClose={handleCloseProfilePopup}>
                <DialogTitle>Property Details </DialogTitle>
                <DialogContent>
                    {state.isProfileLoading ? (
                        <div style={{ textAlign: 'center', padding: 20 }}>
                            <img src={Loader} alt="Loading..." style={{ width: 50, height: 50 }} />
                        </div>
                    ) : state.selectedProfileData ? (
                        <div>
                            <p><strong>Property Name:</strong> {state.selectedProfileData.property_name}</p>
                            <p><strong>City:</strong> {state.selectedProfileData.city}</p>
                            {/* <p><strong>Owner Name:</strong> {state.selectedProfileData.owner_name}</p> */}
                            {/* Add more details as needed */}
                        </div>
                    ) : (
                        <p>No profile data available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseProfilePopup}
                        style={{
                            backgroundColor: '#F87171',
                            color: 'white',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = '#E63946')}
                        onMouseOut={(e) => (e.target.style.backgroundColor = '#F87171')}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );

};

export default CencelBookingUserList;

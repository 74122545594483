import { useNavigate } from 'react-router-dom';

// Higher-Order Component to provide navigation in class components
const withNavigation = (Component) => {
  function ComponentWithNavigationProp(props) {
    let navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }

  return ComponentWithNavigationProp;
};

export default withNavigation;

// import React, { useState, useEffect } from 'react';
// import './dropdown.css'; // Make sure to create this CSS file or include your styles

// const Dropdown = () => {


//   const handleClickOutside = (event) => {
//     if (!event.target.matches('.dropbtn')) {
//     //   setShowDropdown(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('click', handleClickOutside);
//     return () => {
//       window.removeEventListener('click', handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="dropdown">
//       <div className={`dropdown-content ${ 'show'}`} id="myDropdown">
//       <h3>Notification</h3>
//         <a href="#link1">No Notification Found</a>

//       </div>
//     </div>
//   );
// };

// export default Dropdown;


import React, { useState, useEffect } from 'react';
import './dropdown.css';
import BaseUrl from '../../Server/BaseUrl';

const Dropdown = () => {

  const [booking, setBookingdata] = useState([])
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);

  const user_id = localStorage.getItem('user_id');
  console.log("user id id ====>", user_id)

  useEffect(() => {
    fetch(`http://localhost:4000/booking_history/${user_id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        console.log('User profile data:', data.message);
        setBookingdata(data.message);
      })
      .catch(error => {
        setError(error.message);
      });
  }, [user_id]);



  // Function to change background color based on status
  const getStatusClass = (status) => {
    if (status === "in Progress") return "status-confirmed";
    if (status === "in Progress") return "status-confirmed";
    return "status-cancelled";
  };

  // Close dropdown when clicked outside
  const handleClickOutside = (event) => {
    if (!event.target.matches('.dropdown')) {
      // Optionally add some functionality if needed when clicking outside
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // if (loading) return <div>Loading notifications...</div>; // Display while loading
  // if (error) return <div>Error: {error}</div>;

  return (
    <div className="dropdown">
      <div className="dropdown-content show" id="myDropdown">
        <h3>Booking Notifications</h3>
        {booking.length === 0 ? (
          <div>No bookings available</div>
        ) : (
          booking.map((b, index) => (
            <div key={index} className={`notification ${getStatusClass(b.status)}`}>
              <p>
                Hello <strong>{b.
                  user_name
                }</strong>, your booking on{' '}
                <strong>{b.
                  entry_date
                }</strong> expires on{' '}
                <strong>{b.
                  exit_date}</strong>.
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Dropdown;

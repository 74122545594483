import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./List.css";
import BaseUrl from '../../Server/BaseUrl';

const ListingList = ({ props_data }) => {
  const navigate = useNavigate();

  const handleEditClick = (property) => {
    navigate(`/EditForm/${property?.property_id}`, {
      state: { propertyData: property }
    });
  };

  return (
    <div className="container">
      <div className="header">
        <label className="title">Listings</label>
        <Link to='/AddProperty'>
          <button style={{ fontSize: 12 }} className="add-property-button">Add New Property</button>
        </Link>
      </div>
      <div className="grid-containers">
        {props_data?.map((property, index) => (
          <div key={index} className="listing-card">
            <div className="image-container">
              <img
                className="card-image"
                src={
                  property.property_images &&
                  property.property_images[0]?.filename &&
                  `${BaseUrl.BaseUrl}/Images/${property.property_images[0]?.filename}`
                }
                alt="Background"
              />
              <button
                className="edit-button"
                onClick={() => handleEditClick(property)}
              >
                Edit
              </button>
            </div>
            <div className="card-content">
              <div className="card-row">
                <label className="card-heading">{property?.property_name}</label>
                <label className="card-rating">5.0</label>
              </div>
              <div className="card-row">
                <label className="card-description">
                  {property.city} {property.country} -{property?.property_type} View
                </label>
                <label className="card-location">{property.select_view}</label>
              </div>
              <div className="card-price">
                <div className="price">
                  <label className="price-label">From</label>
                  <label className="price-value">${property?.price_per_night}</label>
                  <label className="price-unit">/Night</label>
                </div>
                <label className="distance">2000 km Away</label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingList;

import React, { Component } from 'react';

export default class ClientProfile extends Component {
  // Extended example data array with more clients
  clientData = [
    {
      name: 'Yaroslove K.',
      date: '20 May 2020',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Quam ultrices ultrices aliquet elementum. Enim aliquam quam 
                    adipiscing scelerisque aenean. Egestas orci eu orci sed. 
                    Rutrum faucibus eu et, accumsan magna amet commodo. Amet libero 
                    libero aliquam sapien ut facilisi lacinia nibh nunc. Magna leo 
                    pulvinar diam ut sed arcu semper pharetra. Erat pellentesque sed 
                    est tempus senectus et.`,
      image: 'https://via.placeholder.com/65'  // Placeholder image
    },
    {
      name: 'Alice Johnson',
      date: '15 April 2023',
      description: `Pellentesque habitant morbi tristique senectus et netus et malesuada 
                    fames ac turpis egestas. Phasellus faucibus scelerisque eleifend 
                    donec pretium vulputate sapien nec sagittis. Duis tristique sollicitudin 
                    nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus 
                    vitae congue mauris.`,
      image: 'https://via.placeholder.com/65?text=Alice'  // Placeholder image with text
    },
    {
      name: 'Bob Smith',
      date: '10 June 2022',
      description: `Vivamus arcu felis bibendum ut tristique et egestas quis 
                    ipsum suspendisse. Arcu cursus euismod qus egestas sed. 
                    Sit amet justo donec enim diam vulputate ut pharetra sit amet 
                    est placerat in egestas erat imperdiet. Sollicitudin tempor id 
                    eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper sit.`,
      image: 'https://via.placeholder.com/65?text=Bob'  // Placeholder image with text
    }
    // Add more clients here if needed
  ];

  render() {
    return (
      <div style={{ width: '90%', margin: '0 auto', height: 'auto' , }}>
        {this.clientData.map((client, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <div className='profile-client' style={{ display: "flex", flexDirection: "row" }}>
              <img 
                style={{ width: "65px", height: '65px', marginTop: 40 }}
                src={client.image} 
                alt={client.name} 
              />
              <div style={{ marginLeft: 20, marginTop: 62 }}>
                <label className='profile-decp' style={{ 
                  fontFamily: 'plus Jakarta sans', 
                  fontSize: '17px', 
                  fontWeight: '400', 
                  lineHeight: '23px'
                }}>
                  {client.name}
                </label>
                <label className='profile-decp' style={{ 
                  fontFamily: 'plus Jakarta sans', 
                  color: "#64748B", 
                  fontSize: '12px', 
                  fontWeight: '100', 
                  lineHeight: '24px'
                }}>
                  {client.date}
                </label>
              </div>
            </div>
            <div className='profile-client'>
              <label style={{ 
                fontFamily: 'plus Jakarta sans', 
                color: "#64748B", 
                fontSize: '20px', 
                fontWeight: '400', 
                lineHeight: '24px', 
                display: "flex", 
                marginTop: 29
              }}>
                {client.description}
              </label>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
